.OurProposal {
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: var(--content-padding);
  }

  .HeadlineTop {
    margin-bottom: 0;
  }

  .description {
    margin: 1.5em 0 2.5em;
    font-size: 24px;
    text-align: center;
    padding: var(--content-padding);
  }

  h1 {
    font-size: 40px;
    font-weight: normal;
    margin: 0;
    line-height: 1em;
    padding: 1em;
  }

  ul {
    border-width: 70%;
    padding: 1em;
    flex: 1;
  }

  &__item:first-child {
    &::before {
      content: " ";
    }
  }

  #Platform {
    padding: 10rem 2em 2em 2em;
    border: 2px solid #e88403;
    border-radius: 40px;
    vertical-align: bottom;
  }

  #Data,
  #TemplateLibrary {
    padding: 2em;
    margin-top: 8rem;
    border: 2px solid #fbede0;
    background-color: #fff8f1;
    border-radius: 40px;
    vertical-align: bottom;
    fill: #1d1c3bcc;
  }

  &__item {
    flex-basis: 30%;
    min-width: 300px;
    max-width: 380px;
    margin: 1em;
    overflow: hidden;
    flex: 1;
    border: 1px solid black;
    display: flex;
    flex-direction: column;

    .top {
      text-align: center;
    }

    .title {
      height: 108px;
      display: block;
    }

    h4 {
      font-weight: bold;
      font-size: 24px;
      padding: 1em 1em;
      text-align: center;
    }

    p {
      font-size: 18px;
      line-height: 1.5em;
      text-align: justify;
    }

    .icon {
      text-align: center;
      padding-bottom: 24px;
    }

    li {
      font-size: 18px;
      padding: .75em 0;

      display: flex;
      align-items: center;

      &:hover {
        background-color: #ff900012;
        border-radius: 4px;
      }

      svg {
        width: 16px;
        height: 16px;
        color: var(--success);
        flex-shrink: 0;
        margin-right: 1em;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
