.PrivacyPolicy {
  &__content {
    max-width: 1100px;
    // border: solid 1px red;
    padding: 0 3em;

    p {
      line-height: 1.5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--dark);
    }

    h2 {
      padding-top: 2em;
    }
  }
}
