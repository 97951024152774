.problemPitch {
  background: var(--lightest);

  article {
    position: relative;
    padding: var(--content-padding);
  }

  li {
    position: relative;
    display: flex;
    padding: 2em 1em;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2em 0;
    // background: var(--lighter);
    // border: solid 1px blue;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  h4 {
    font-weight: normal;
    margin: .5em 0;
    font-family: var(--heading);
    margin: 0;
  }

  p {
    line-height: 1.5;
    margin: .75em 0;
    text-align: justify;
  }

  &__item {
    // border: solid 1px red;
    // border: solid 1px blue;
    flex: 1 1;
    min-width: 300px;
    margin: 0 2em;
  }

  &__image {
    display: flex;
    min-width: 300px;
    justify-content: center;
    // border: solid 1px red;
    flex: 1 1;
    margin: 0 2em;

    svg {
      width: 100%;
      object-fit: cover;
      max-width: 100%;
      min-width: 300px;
      height: unset;
    }
  }
}