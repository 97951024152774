.ToolsForGrowth {
  article {
    position: relative;
    padding: var(--content-padding);
  }

  .svg-line {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding-top: 2em;
    padding-right: 8em;
    height: 100%;
  }

  li {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // border: solid 1px red;

    &:after {
      content: '';
      flex: 1;
      min-width: 300px;
      height: 2em;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  h4 {
    font-size: 30px;
    margin: .5em 0;
    font-family: var(--heading);
    margin: 0;
  }

  p {
    line-height: 1.5;
    font-size: 18px;
    margin: .75em 0;
  }

  &__item {
    // border: solid 1px red;
    flex: 0;
    flex-basis: 500px;
    width: 50%;
  }
}
