.Benefits {

  article {
    position: relative;
    padding: var(--content-padding);
  }

  h4 {
    font-size: 30px;
    margin: .5em 0;
    font-family: var(--heading);
    margin: 0;
  }

  p {
    line-height: 1.5;
    font-size: 18px;
    margin: .75em 0;
  }
  
  &__items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    padding: var(--content-padding);
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;

    &__card {
      max-width: 500px;
      padding: 20px 5px 20px 10px;
      border-radius: 10px;
      margin: 20px 5px 20px 10px;
      // border: 1px solid #ccc;
      background: #ffffff;
      -webkit-filter: drop-shadow(0 1px 1px #0001);
      filter: drop-shadow(rgba(0, 0, 0, 0.067) 0px 1px 1px);
      box-shadow: 0 1px 3px rgba(232, 133, 3, 0.22);

      &__top {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        line-height: 87px;
      }

      &__headline {
        display: flex;
        flex-direction: row;
        align-content: space-between;
        width: 100%;
        font-size: 18px;
        font-family: var(--regular),
          'Helvetica Neue', sans-serif;
        font-weight: bold;

      }


      &__details {
        text-align: justify;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        // max-height: 250px;
        padding: 7px 10px;
        text-align: justify;
        font-size: 1em;

        p {
          margin-top: 1em;
        }
      }


      &__nav {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .source {
        display: flex;
        align-items: flex-start;
        margin-top: 16px;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 70px;

        .who {
          height: 70px;
          display: flex;
          align-content: center;
          flex-direction: column;
          justify-content: flex-end;

          .name,
          .title {
            line-height: 1.3;
          }
        }
      }

      .imageContainer {
        padding-right: 15px;
        // max-width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;


        img {
          // position: absolute;
          // top: 50%;
          display: block;
          max-width: 40px;
          max-height: 100%;

          // border: 1px solid black;
        }
      }
    }
  }
}

.collapsed {
  max-height: 0px;
}