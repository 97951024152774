.menu {
  input[type=checkbox] {
    display: none;
  }

  .menuToggle {
    display: none;
    font-size: 24px;
    user-select: none;
  }


  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;


    li {
      position: relative;
    }

    li:hover .dropdown li {
      display: block;
      padding: 5px 20px;
      // line-height: 2em;
    }

    .arrowUp {
      display: none;
    }

    li:hover .arrowUp {
      display: block;
    }

    .arrowUp,
    .arrowDown {
      padding-left: 3px;
    }

    li:hover .arrowDown {
      display: none;
    }

    ul.dropdown {
      width: 350px;
      text-align: left;

      li {
        width: 100%;

        a {
          justify-content: left;
        }
      }
    }

    .dropdown {
      position: absolute;
      top: 40px;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #fff8f1;
      border-radius: 8px;
      margin: 0px 0 0 0;
      padding: 10px;

      li {
        display: none;
        width: 100%;
        position: relative;
        min-height: 20px;
        line-height: 30px;

        :hover,
        :active {
          background-color: #f9d7b6;
          border-radius: 6px;
          // width: 100%;
        }
      }
    }
  }

  a {
    color: #1d1c3b;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    text-decoration: none;
    width: 100%;
  }
}

.main-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1300px;
  flex: 1;
  padding: 0.7em;
  justify-content: space-between;


  .parentMenuItem {
    display: flex;
    flex-direction: column;
  }

  .menuItem {
    color: #1d1c3b;
    text-decoration: none;
    font-size: 18px;
    font-weight: bolder;
    font-family: var(--heading);
    padding: 5px 12px 5px 12px;
    width: 100%;
  }

  .logo {
    width: 180px;

    svg {
      color: var(--dark-orange);
    }
  }

  .signin {
    color: #e98400;
    text-decoration: none;
    font-size: 18px;
    font-weight: bolder;
    font-family: var(--heading);
  }

  .startfree {
    background-color: #e98400;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bolder;
    font-family: var(--heading);
  }
}

@media (max-width: 768px) {

  .menu {
    .menuToggle {
      display: block;
      padding: 5px 20px;
      text-align: left;
    }

    input[type=checkbox]~li {
      display: none;
    }

    input[type=checkbox]:checked~li {
      display: block;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      li {
        align-content: flex-start;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    ul.dropdown {
      flex-direction: column;
      position: relative;
      top: unset;
      list-style-type: disc;
    }

    .startfree {
      margin: 0px 0px;
      width: 120px;
    }

    ul.dropdown li {
      display: block;

      ::before {
        content: "\00BB\0020";
        padding-right: 6px;
      }

      :hover,
      :active {
        background-color: unset;
      }
    }

    ul li:hover {

      .arrowUp {
        display: none;
      }

      .arrowDown {
        display: block;
      }
    }
  }


  .main-header {
    flex-direction: column;
  }
}