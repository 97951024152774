.Features2 {
  background: var(--lightest);
  article {
    position: relative;
    padding: var(--content-padding);
  }

  li {
    position: relative;
    display: flex;
    padding: 0;
    align-items: center;
    flex-wrap: wrap;
    // background: var(--lighter);
    // border: solid 1px blue;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  h4 {
    font-weight: normal;
    margin: .5em 0;
    font-family: var(--heading);
    margin: 0;
  }

  p {
    line-height: 1.5;
    margin: .75em 0;
  }

  &__item {
    // border: solid 1px red;
    // border: solid 1px blue;
    flex: 1;
    min-width: 300px;
  }

  &__image {
    display: flex;
    justify-content: center;
    // border: solid 1px red;
    flex: 1;
    margin: 1em;

    svg {
      width: 80vw;
      object-fit: cover;
      max-width: 100%;
      min-width: 300px;
    }
  }
}
