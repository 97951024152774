.Principles {
  article {
    display: flex;
    flex-wrap: wrap;
    padding: var(--content-padding);

    & > * {
      flex: 1;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    // min-width: 300px;
    flex-basis: 150px;
    // border: solid 3px blue;
    margin: 5em 0;
  }

  .annotation {
    position: relative;
    padding: 3em 0;
    flex-basis: 500px;

    li {
      padding: 1.5em 4em;
      line-height: 1.6;
      font-size: 24px;

      display: flex;
      align-items: flex-start;

      svg {
        margin-top: 7px;
        flex-shrink: 0;
        color: var(--dark);
        margin-right: 1em;
      }
    }
    .annotation__label {
      font-weight: bold;
    }

    &:before {
      content: '';
      position: absolute;
      width: 8px;
      top: 0;
      bottom: 0;
      background: var(--dark);
      border-radius: 50vh;
    }
  }

  @media (max-width: 600px) {
    .annotation {
      li {
        padding: 0.1rem 0.5rem;
        line-height: 1.3;
        font-size: 18px;
      }
      .annotation__label {
        font-weight: bold;
      }
    }
  }
}
