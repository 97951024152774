.SecurityPolicy {
  &__content {
    max-width: 1100px;
    // border: solid 1px red;
    padding: 0 2em;

    p {
      line-height: 1.5;
    }

    .highlights{
      ul{
        margin-top:10px;
      }
      p{
        padding-left:60px;
        margin:0px;
      }

      li {
        padding-top:15px;
        padding-bottom:15px;
      }
    }

    .highlightTitle{
      font-size: 1.5em;
      color: var(--dark);
      font-weight: 500;
      padding: 0 0 12px 60px;
    }

    .himg{
      float:left;
      
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--dark);
    }

    h2 {
      padding-top: 2em;
    }
  }
}
  