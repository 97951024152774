.Workflow {
  // background: var(--lightest);
  header {
    padding: 20px 0 80px 0;
    margin: .5em 0;
  }

  h4 {
    margin: .5em 0;
  }

  .HeadlineTop {
    margin-bottom: 0;
  }

  .description {
    margin: 1.5em 0 2.5em;
    font-size: 24px;
    padding: var(--content-padding);
  }
  svg {
    width: 100%;
    padding: var(--content-padding);
  }

  @media (max-width: 600px) {
    svg {
      max-height: 350px;
      height: unset;
    }
  }
}
