.WhatPainsWeSolve {

  // background: var(--lightest);
  article {
    position: relative;
    padding: var(--content-padding);
  }

  li:last-child {
    margin-bottom: 0;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: justify;
    // background: var(--lighter);
    // border: solid 1px blue;
    margin-bottom: 5vh;

    &.Pipeline {
      background-color: #eff2f3;
      border-radius: 20px;
      padding: 20px 20px;
      box-shadow: 0 3px 12px 1px rgba(234, 238, 240, .7);
    }

    &.Templates {
      background-color: #ebf6f7;
      padding: 20px 20px;
      border-radius: 20px;
      box-shadow: 0 3px 12px 3px rgba(235, 246, 247, .7);
    }

    &.Repository {
      background-color: #fff8ef;
      padding: 20px 20px;
      border-radius: 20px;
      box-shadow: 0 3px 12px 3px rgba(255, 248, 239, .7);
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;

      .WhatPainsWeSolve__item {
        justify-content: left;
        margin-left: 0 !important;
        padding-left: 2rem;
      }

      .WhatPainsWeSolve__image {
        justify-content: right;
        text-align: right;
      }
    }
  }

  .two {
    padding-left: 30px;
    // background: #d1d1d1;
    // border-radius: 4px;
    // box-shadow: 0 3px 12px 3px rgba(234, 238, 240, .52);
    // padding:1em 1em;
  }
  .three {
    padding-left: 60px;
    // background: #a2a2a2;
    // border-radius: 4px;
    // box-shadow: 0 3px 12px 3px rgba(234, 238, 240, .52);
    // margin:1em 1em;
  }

  @media (max-width: 600px) {
    li {
      &:nth-child(odd) {
        .WhatPainsWeSolve__item {
          padding-left: 1rem;
        }
      }
    }

    .two,
    .three {
      padding-left: 0px;
    }

    &__image {
      flex: 1;
    }
  }

  h4 {
    font-weight: bold;
    font-size: 24px;
    margin: .5em 0;
    font-family: var(--heading);
    margin: 0;
  }

  h5 {
    font-weight: bolder;
    font-size: 18px;
    margin: 1.5em 0 0 0;
    font-family: var(--heading);
    // color: #595959;
  }

  p {
    line-height: 1.5;
    font-size: 18px;
    margin: .5em 0;
    // color: #595959;
  }

  &__item {
    // border: solid 1px red;
    // border: solid 1px blue;
    flex: 1;
    min-width: 250px;
  }

  &__image {
    // display: flex;
    // justify-content: center;
    // padding: 0 1rem 0 0;
    // margin-left: 10vh;
    // border: solid 1px red;
    flex: 1;
    min-width: 250px;
    // margin: 1em 3.5em;
    // width: 50vw;

    img {
      object-fit: contain;
      max-width: 100%;
      min-width: 250px;
      max-height: 450px;
      border-radius: 12px;
      // box-shadow: 0 6px 12px rgba(180, 180, 180, 0.8);
    }
  }
}
