.HeadlineTop {
  // border: solid 1px red;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--text-big);
  font-family: var(--heading);
  font-weight: 700;
  text-align: center;
  z-index: 1;
  width: 100%;
  margin-bottom: #{"min(2em, 10%)"};
  padding: var(--content-padding);

  .TopHighlight {
    border-top: orange solid;
    border-width: 2px;
    padding-top: 40px;
    width: 60px;
    text-align: center;
  }
}
