.CaseStudyBoxifier {
  &__content {
    max-width: 950px;
    padding: 0 5em 5em 5em;
    color: #292929;
    text-align: justify;

    a {
      text-decoration: none;
      color: unset;
    }

    .company {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      img {
        padding-top: 2em;
        width: 200px;
      }
    }

    span.figure {
      font-size: 24px;
      word-break: keep-all;
      white-space: nowrap;
      font-feature-settings: "tnum" on, "lnum" on;
      font-weight: 400;
      font-family: var(--monospace);
    }

    table {
      width: 100%;
      font-size: 18px;
      text-align: right;
      font-feature-settings: "tnum" on, "lnum" on;
      border-collapse: collapse;
      margin-top: 3em;
      page-break-inside: avoid;

      td {
        padding: 1em 0;
      }

      .linetop td {
        border-top: 3px solid #ffe6c6;

      }

      .headrow {
        color: #999;
        font-family: 'Poppins';
        text-align: right;

      }

      .headcol {
        font-weight: bold;
      }
    }

    p {
      line-height: 1.5em;
      font-size: 18px;
      page-break-inside: avoid;
    }

    ul {
      list-style-type: disc;
      margin-left: 30px;

      li {
        padding-top: 10px;
        padding-left: 10px;
        page-break-inside: avoid;
      }

      ::marker {
        content: "\00BB\0020";
        font-size: 24px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #292929;
    }

    h2 {
      padding-top: 2em;
    }

    .quote {
      box-shadow: #ffe6c6 3px 0px 0px 0px inset;
      font-style: italic;

      // font-size: 1em;
      font-size: var(--text-medium);
      margin: 2em 0 1.5em -20px;
      padding: 0 50px;

      p {
        font-size: var(--text-medium);
        page-break-inside: avoid;
      }

      .owner {
        text-align: right;
      }
    }
  }
}