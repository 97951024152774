.layout__content {
  --content-padding: 0 #{"min(50px, 5%)"};
  --row-padding: 0 #{"min(30px, 3%)"};

  & > section {
    padding: #{"min(3em, 10%)"} 3%;
    display: flex;
    flex-direction: column;
    // border: solid 1px orange;
    align-items: center;
    // gap: 10em;

    // & ~ * {
    //   margin: 10em 0;
    // }

    article,
    .content {
      max-width: 1280px;
      // border: solid 1px red;
      width: 100%;

      & ~ article {
        // border: solid 3px red;
        margin-top: #{"min(3em, 10%)"};
      }
    }
  }
}
