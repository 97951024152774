.GetEfficiency {
  // border: solid 1px red;
  &__content {
    display: flex;
    flex-wrap: wrap;
    // border: solid 1px red;
    justify-content: space-evenly;
    padding: var(--content-padding);
  }

  h1 {
    font-size: 40px;
    font-weight: normal;
    margin: 0;
    line-height: 1em;
  }
  
  &__item {
    // border: solid 1px blue;
    flex-basis: 22%;
    min-width: 270px;
    // margin-bottom: 2em;

    h4 {
      font-weight: normal;
      font-size: 24px;
    }

    li {
      font-size: 18px;
      padding: .75em 0;

      display: flex;
      align-items: center;
      &:hover{
        background-color: #ff900012;
        border-radius: 4px;
      }

      svg {
        width: 16px;
        height: 16px;
        color: var(--success);
        flex-shrink: 0;
        margin-right: 1em;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
