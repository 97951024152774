@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: 'PMackinac';
  src: url(../fonts/P22MackinacProMedium.woff2) format("woff2");
}



:root {
  --heading: 'Nunito Sans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --regular: 'Raleway', -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif;
  --monospace: 'IBM Plex Mono', 'Curier new', monospace;
  --primary: #06412f;
  --secondary: #648c81;

  --darker: #1e2f41;
  --darker-rgb: 30, 47, 65;
  --dark: #46678a;
  --dark2: #2e435d;
  --medium: #6284a8;
  --lighter: #adc0d5;
  --lightest: #ff900010;
  --light: #edeff1;

  --dark-orange: #ff9000;
  --grey: #e9e8e5;

  --success: #04be9d;
  --warning: #f7b847;
  --error: #b31412;

  --brand-darker: var(--darker);
  --brand-dark: var(--dark);
  --brand-medium: var(--medium);
  --brand-secondary: var(--dark-orange);

  --text: 18px;
  --text-small: 14px;
  --text-medium: 23px;
  --text-big: 30px;
  --text-headline: 45px;
}


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--regular);
  font-size: var(--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "tnum" on, "lnum" on;
  width: 100%;
  // overflow-x: hidden;
}


h1,
h2,
h3,
h4 {
  font-family: var(--heading);
  font-weight: 600;
}

h1 {
  font-size: var(--text-headline);
}

h2 {
  font-size: var(--text-big);
}

h3 {
  font-size: var(--text-medium);
}

h4 {
  font-size: var(--text-small);
}

p {
  font-size: var(--text);
  font-feature-settings: "tnum" on, "lnum" on;
  text-size-adjust: 100%;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input,
textarea,
button {
  &:focus {
    outline: none;
    box-shadow: 0 0px 2px var(--dark);
  }
}

input,
textarea {
  padding: 1em;
  border-radius: 4px;
  border: solid 1px #d5d4de;
  font-size: 16px;
}

.layout {
  // border: solid 1px red;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: center;
    background: #fff8f1;
    position: sticky;
    top: 0;
    z-index: 100;

    box-shadow: 0 1px 3px 0 #f3f3f3;
  }

  &__content {
    flex: 1;
  }
}

.desc {
  font-size: 11px;
}

.btn {
  border: solid 2px var(--dark);
  background: none;
  color: inherit;
  // font-size: 11px;
  padding: 1em 5em;
  border-radius: 50vh;
  font-size: 16px;
  text-decoration: none;

  &.small {
    padding: .75em 3em;
  }

  &.light {
    border-color: var(--grey);
  }

  &.primary {
    background: var(--dark);
    border-color: var(--dark);
    color: white;
  }

  &.dark {
    background: var(--brand-dark);
    color: white;
  }
}