.Stats {
  padding: 30px 0 !important;

  &__content {
    display: flex;
    flex-wrap: wrap;
    // border: solid 1px red;
    justify-content: space-evenly;
    padding: var(--content-padding);
    align-items: center;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    // padding: var(--row-padding);
    // align-items: center;
    // margin-top: 5px;
    // font-size: var(--text);

    &__card {
      display: flex;
      flex-direction: row;
      align-items: center;

      max-width: 350px;
      padding: 10px 5px;
      margin: 10px 5px;
      background: #ffffff;

      &__quote {
        font-size: 2em;
        word-break: keep-all;
        white-space: nowrap;
        text-align: center;
        // font-style: italic;
        font-feature-settings: "tnum" on, "lnum" on;
        font-weight: 400;
        font-family: var(--monospace);
        max-width: 150px;
        margin-right: 15px;

        strong {
          font-weight: bold;
        }
      }

      &__info {
        font-size: 18px;
        max-width: 210px;
        text-align: justify;
      }

      &__imageContainer {
        height: 100%;
        max-width: 52px;
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 10px 0 0px;

        img {
          // position: absolute;
          // top: 50%;
          display: block;
          max-width: 100%;
          max-height: 100%;

          // border: 1px solid black;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &__item__card {
      margin: 0px 0 0 35px;
    }
  }
}