.Footer {
  // border: solid 1px red;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: var(--heading);

  nav {
    flex: 1;
    max-width: 1000px;
    // border: solid 1px blue;
    width: 100%;
    padding: 0 5em;

    ul {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .allrights {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    div {
      margin-top: 20px;
    }

    .social {
      .channel {
        padding: 10px 20px;

        a {
          border-bottom: none;
        }

        .logoIcon:hover {
          fill: #FF9E1F;
          transition: all 0.2s;

        }
      }
    }
  }

  a {
    color: var(--dark);
    text-decoration: none;
    border-bottom: solid 1px var(--dark);
    padding-bottom: 2px;
  }

  &__logo {
    padding: 5em;
    color: var(--dark-orange);
  }

  @media only screen and (max-width: 800px) {
    nav {
      display: none;
    }
  }
}