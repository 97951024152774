.Pricing {
  &__content {
    display: flex;
    flex-wrap: wrap;
    // border: solid 1px red;
    justify-content: space-evenly;
    padding: var(--content-padding);
  }

  ul {
    border-width: 70%;
    padding: 1em;
    flex: 1;
  }

  li {
    font-size: 18px;
    padding: .75em 0;

    display: flex;
    align-items: center;

    svg {
      flex-shrink: 0;

      margin-right: 1em;
      fill: var(--success);
      padding: 3px;
      border-radius: 50vh;
    }
  }

  &__item {
    // border: solid 1px blue;
    flex-basis: 30%;
    min-width: 300px;
    max-width: 500px;
    box-shadow: 0 0 14px -5px #4f4e74;
    border-radius: 8px;
    border-top: 10px solid #fdd1a5;
    margin: 1em;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;


    &:first-child {
      border-top: 10px solid #bfe180;
    }

    &:last-child {
      // border-top: 10px solid #ff9595;
      border-top:10px solid #fdd1a5;
    }

    header {
      // border: solid 1px red;
      padding: 1em 2em;
      background: white;
    }

    h4 {
      font-size: 20px;
      margin: .5em 0;
      font-weight: bold;
      text-align: left;
      // color: var(--dark-orange);
    }

    .price {
      // border: solid 1px red;
      font-size: 24px;
      padding: 2em 0;
      // border-radius: .5em;
      // color: var(--lighter);
      // background: var(--darker);
      text-align: left;
      display: flex;
      // align-items: center;
      // justify-content:left;
      height: 40px;

      span {
        font-size: 18px;
        margin: 0 10px;
      }
    }

    .actions {
      // border: solid 1px red;
      border-bottom: 1px solid #e4e4e5;
      display: flex;
      justify-content: left;
      padding-bottom: 2em;

      .btn:hover {
        opacity: 0.8;
      }
    }
  }

  .all-plans {
    margin: 2em 0;
    padding: var(--content-padding);
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
      font-size: 24px;
    }

    ul {
      column-count: 2;
      column-gap: 40px;

      li:hover {
        background-color: #ff900012;
        border-radius: 4px;
      }

      li {
        break-inside: avoid;
      }
    }

    @media only screen and (max-width: 900px) {
      ul {
        column-count: 1;

        li:hover {
          background-color: #ff900012;
          border-radius: 4px;
        }
      }
    }

    svg {
      color: white;
      flex-shrink: 0;

      margin-right: 1em;
      color: var(--success);
      background: none;
      padding: 3px;
      border-radius: 50vh;
    }
  }
}