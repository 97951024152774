.Logos {
  &__content {
    display: flex;
    flex-wrap: wrap;
    // border: solid 1px red;
    justify-content: space-evenly;
    padding: var(--content-padding);
    align-items: center;
  }

  &__item {
    height: 70px;
    max-width: 160px;
    line-height: 70px;
    margin: 30px 13px;
    padding: 0px 0px;
    text-align: center;

    &__caption {
      margin-top: -5px;
      line-height: 17px;
    }

    img {
      // position: absolute;
      // top: 50%;
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
      // filter: grayscale(0.1);
    }
  }

  &__quotes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: var(--content-padding);
    align-items: center;
    margin-top: 10px;
    font-size: var(--text);

    &__card {
      width: 500px;
      padding: 40px 24px;
      border-radius: 10px;
      margin: 30px 10px;
      border: 1px solid #ccc;
      background: #ffffff;
      -webkit-filter: drop-shadow(0 1px 6px #0001);
      filter: drop-shadow(rgba(0, 0, 0, 0.067) 0px 1px 6px);

      .quote {
        text-align: justify;
        font-style: italic;
        font-weight: 500;

        strong {
          font-weight: bold;
        }
      }

      .source {
        display: flex;
        align-items: flex-start;
        margin-top: 16px;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 70px;

        .who {
          height: 70px;
          display: flex;
          align-content: center;
          flex-direction: column;
          justify-content: flex-end;

          .name,
          .title {
            line-height: 1.3;
          }
        }
      }

      .imageContainer {
        height: 70px;
        max-width: 160px;
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: flex-end;

        img {
          // position: absolute;
          // top: 50%;
          display: block;
          max-width: 100%;
          max-height: 100%;

          // border: 1px solid black;
        }
      }
    }

  }
}