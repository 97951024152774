.ContentAware {
  background: var(--lightest);
  position: relative;
  // color: white;
  // min-height: 420px;


  .content {
    margin-top: 2em;
    display: flex;
    // padding: var(--content-padding);
    flex-wrap: wrap;
    // border: solid 1px orange;
    justify-content: space-evenly;
    padding: 20px;
  }

  article {
    flex: 1 1;
    flex-basis: 500px;
    padding: 30px 70px 0 0;
    font-family: Raleway !important;
    text-align: justify;
  }

  h1 {
    font-size: 50px;
    margin: 0;
    line-height: 1.5em;
    text-align: center;
    // font-weight: 600;
    // font-family: Raleway!important;
  }

  h2 {
    font-size: 28px;
    font-family: Raleway !important;
    font-weight: 400;
  }

  .highlighted {
    background-color: #fbdfc3;
    padding: 4px 6px;
    line-height: 24px;
    border-radius: 3px;
  }

  h3 {
    font-size: 22px;
    font-family: Raleway !important;
    font-weight: 400;
  }

  h4 {
    font-size: 20px;
    font-family: Raleway !important;
    font-weight: 400;
  }

  p {
    font-size: 18px;
    color: var(--lighter);
    line-height: 1.6;
  }

  &__actions {
    margin: 6em 0;

    p {
      font-size: 18x;
    }

    .btn {
      border: #ffffff solid 1px;
      background: #1d1c3b;
      font-size: 18px;
    }

    .btn:hover {
      background: #242255;
    }
  }

  aside {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-basis: 400px;


    ul {
      flex: 1 3;
      background: white;
      color: var(--darker);
      // border: solid 1px red;
      padding: 2em 1em;
      margin: 0;
      border-radius: .5em;
      box-shadow: 0 2px 5px rgba(232, 133, 3, 0.52);
      font-size: 20px;

      li {
        padding: 1em;
        display: flex;
        align-items: center;

        svg {
          margin-right: 2em;
          width: 20px;
          height: 20px;
          // border: solid 1px red;
          border-radius: 50vh;
          padding: 2px;
          background: var(--success);
          color: white;
          flex-shrink: 0;
        }
      }
    }
  }
}